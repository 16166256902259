import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About // Kublic</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>About // Kublic</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p></p>
              <blockquote>
                <p>
                  “All that I’ve done, thought or been is a series of submissions, either to a false
                  self that I assumed belonged to me because I expressed myself through it to the
                  outside, or to a weight of circumstances that I supposed was the air I breathed.
                  In this moment of seeing, I suddenly find myself isolated, an exile where I’d
                  always thought I was a citizen. At the heart of my thoughts I wasn’t I.”
                </p>

                <p>FERNANDO PESSOA, The Book of Disquiet</p>
              </blockquote>


              <br></br>

              <blockquote>
                <p>
                  “The composition of vast books is a laborious and impoverishing extravagance. 
                  To go on for five hundred pages developing an idea whose perfect oral exposition 
                  is possible in a few minutes! A better course of procedure is to pretend that 
                  these books already exist, and then to offer a resume, a commentary.”
                </p>

                <p>JORGE LUIS BORGES, Ficciones</p>
              </blockquote>  

            
              <br></br>

              <blockquote>
                <p>
                  “Most, perhaps even all, good work (or, okay, work that excites me) eludes easy generic
                  classification; once we know it's coloring entirely within the lines of 'novel' or 'memoir'
                  or 'Hollywood movie', I honestly don't see how anything emotionally or intellectually 
                  interesting can happen for the reader.”
                </p>

                <p>DAVID SHIELDS, Reality Hunger</p>
              </blockquote>                

              <p>Any resemblance to actual events or locales or persons, living or dead, is entirely coincidental.</p>         

              <p>All images on the site are sourced from <a href="https://unsplash.com/">Unsplash</a>.</p>

            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
